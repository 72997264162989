import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";

const navbar = props => {
  const { location } = props;

  let adminMenu = null;
  let userMenu = null;
  let logoutBtn = null;
  if (props.auth.isAuthenticated) {
    if (props.auth.user.role === "ADMIN") {
      adminMenu = (
        <>
          <Nav.Link as={Link} to="/admin">
            Dashboard
          </Nav.Link>
          <Nav.Link as={Link} to="/admin/users">
            Users
          </Nav.Link>
        </>
      );
    } else {
      userMenu = (
        <>
          <Nav.Link as={Link} to="/profile">
            Profile
          </Nav.Link>
          <Nav.Link as={Link} to="/payments">
            Payments
          </Nav.Link>
          <Nav.Link as={Link} to="/plan">
            Plan
          </Nav.Link>
          <Nav.Link as={Link} to="/upgradeplan">
            UpgradePlan
          </Nav.Link>
          <Nav.Link as={Link} to="/downgradeplan">
            DowngradePlan
          </Nav.Link>
          <Nav.Link as={Link} to="/myaddresses">
            MyAddresses
          </Nav.Link>
        </>
      );
    }

    logoutBtn = (
      <Button type="button" className="ml-auto" onClick={props.logoutClicked}>
        Logout
      </Button>
    );
  } else {
    userMenu = (
      <>
        <Nav.Link as={Link} to="/login">
          Login
        </Nav.Link>
        <Nav.Link as={Link} to="/register">
          Register
        </Nav.Link>
      </>
    );
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand as={Link} to="/">
        {props.auth.user.role === "ADMIN" ? "Admin" : "Home"}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav activeKey={location.pathname} className="mr-auto">
          {adminMenu}
          {userMenu}
        </Nav>

        {props.auth.user.name !== undefined &&
        props.auth.user.name.length > 0 ? (
          <span className="text-light">Hello, {props.auth.user.name}</span>
        ) : null}

        {logoutBtn}
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(navbar);

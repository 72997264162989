import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import jwt_decode from "jwt-decode";
import setAuthToken from "./redux/utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./redux/actions/authActions";
import { Provider } from "react-redux";
import store from "./redux/store";

import Layout from "./hoc/Layout/Layout";
import Intro from "./components/Pages/Intro/Intro";
import HowItWorks from "./components/Pages/TextPages/HowItWorks";
import PrivacyTerms from "./components/Pages/TextPages/PrivacyTerms";

// Admin section
import AdminRoute from "./components/AdminRoute/AdminRoute";
import AdminDashboard from "./containers/Admin/Dashboard/Dashboard";
import AdminUsers from "./containers/Admin/Users/UserList/UserList";
import AdminAddUser from "./containers/Admin/Users/AddUser/AddUser";

// User section
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Login from "./containers/Auth/Login/Login";
import Register from "./containers/Auth/Register/Register";
import Profile from "./containers/Pages/Profile/Profile";
import Payments from "./containers/Pages/Payments/Payments";
import Plan from "./containers/Pages/Plan/Plan";
import UpgradePlan from "./containers/Pages/UpgradePlan/UpgradePlan";
import DowngradePlan from "./containers/Pages/DowngradePlan/DowngradePlan";
import MyAddresses from "./containers/Pages/MyAddresses/MyAddresses";
import ConfirmEmail from "./containers/Auth/ConfirmEmail/ConfirmEmail";

// Global CSS rules
import "./App.css";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Layout>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />

            <Route path="/" exact component={Intro} />

            <Route path="/how-it-works" exact component={HowItWorks} />
            <Route path="/privacy-terms" exact component={PrivacyTerms} />

            <Switch>
              {/* USER ROUTES */}
              <PrivateRoute
                exact
                path="/profile"
                component={Profile}
              />
              <PrivateRoute
                exact
                path="/payments"
                component={Payments}
              />
              <PrivateRoute
                exact
                path="/plan"
                component={Plan}
              />
              <PrivateRoute
                exact
                path="/upgradeplan"
                component={UpgradePlan}
              />
              <PrivateRoute
                exact
                path="/downgradeplan"
                component={DowngradePlan}
              />
              <PrivateRoute
                exact
                path="/myaddresses"
                component={MyAddresses}
              />

              {/* ADMIN ROUTES */}
              <AdminRoute exact path="/admin" component={AdminDashboard} />
              <AdminRoute exact path="/admin/users" component={AdminUsers} />
              <AdminRoute
                exact
                path="/admin/users/add-user"
                component={AdminAddUser}
              />
            </Switch>

            <Route path="/email/confirm/:id" component={ConfirmEmail} />
          </Layout>
        </Router>
      </Provider>
    );
  }
}

export default App;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { countryList } from "../../../../config/config";
import { getCountrySelectListOptions } from "../../../../utils/utils";
import { adminAddUser } from "../../../../redux/actions/adminActions";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      surname: "",
      username: "",
      email: "",
      password: "",
      password2: "",
      mobilePhone: "",
      country: "",
      validated: false,
      disableAddUserBtn: false,
      projects: {},
      errors: {}
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    this.setState({ disableAddUserBtn: true });

    if (form.checkValidity()) {
      const newUser = {
        name: this.state.name,
        surname: this.state.surname,
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        password2: this.state.password2,
        mobilePhone: this.state.mobilePhone,
        country: this.state.country
      };

      this.props.adminAddUser(newUser);
    }
  };

  render() {
    // const { user } = this.props.auth;
    const { validated, errors, disableAddUserBtn } = this.state;

    return (
      <>
        <h1>Add User</h1>
        <br />
        <br />
        <Container>
          <Row className="justify-content-lg-center">
            <Col lg="6">
              <Form validated={validated} onSubmit={e => this.handleSubmit(e)}>
                <Form.Text style={{ color: "red" }}>{errors.server}</Form.Text>

                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Enter username"
                    required
                    onChange={e => this.handleChange(e)}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.username}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    onChange={e => this.handleChange(e)}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.password}
                    {errors.passwordincorrect}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Confirm password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password2"
                    placeholder="Confirm password"
                    onChange={e => this.handleChange(e)}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.password2}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    required
                    onChange={e => this.handleChange(e)}
                  />
                  <Form.Text style={{ color: "red" }}>{errors.name}</Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Surname</Form.Label>
                  <Form.Control
                    type="text"
                    name="surname"
                    placeholder="Enter your surname"
                    required
                    onChange={e => this.handleChange(e)}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.surname}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    required
                    onChange={e => this.handleChange(e)}
                  />
                  <Form.Text style={{ color: "red" }}>{errors.email}</Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Mobile phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobilePhone"
                    placeholder="Enter your phone number"
                    required
                    onChange={e => this.handleChange(e)}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.mobilePhone}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    name="country"
                    required
                    onChange={e => this.handleChange(e)}
                  >
                    <option value="">Choose your country</option>
                    {getCountrySelectListOptions(countryList)}
                  </Form.Control>
                </Form.Group>

                <Button variant="secondary" onClick={this.handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="float-right"
                  disabled={disableAddUserBtn}
                >
                  {disableAddUserBtn ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Please wait...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

AddUser.propTypes = {
  adminAddUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { adminAddUser }
)(AddUser);

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { API_URL } from "../../../config/config";
import { countryListReactSelect } from "../../../utils/utils";
import { registerUser } from "../../../redux/actions/authActions";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";

// SOCIAL LOGIN DEPENDENCIES
import io from "socket.io-client";
import OAuth from "../SocialLogin/OAuth";
const socket = io(API_URL);
const providers = ["twitter", "google", "facebook"];
// SOCIAL LOGIN DEPENDENCIES END

class Register extends Component {
  state = {
    name: "",
    surname: "",
    username: "",
    email: "",
    password: "",
    password2: "",
    mobilePhone: "",
    country: "",
    socialId: "",
    validated: false,
    disableSignupBtn: false,
    errors: {}
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  static getDerivedStateFromProps(newProps) {
    if (Object.keys(newProps.errors).length > 0) {
      return {
        errors: newProps.errors,
        disableSignupBtn: false
      };
    }

    // No state update necessary
    return null;
  }

  handleChange = (e, target) => {
    let name;
    let value;

    if (target === "country") {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    this.setState({ disableSignupBtn: true });

    if (form.checkValidity()) {
      const newUser = {
        name: this.state.name,
        surname: this.state.surname,
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        password2: this.state.password2,
        mobilePhone: this.state.mobilePhone,
        country: this.state.country
      };

      this.props.registerUser(newUser, this.props.history);
    }
  };

  render() {
    const { validated, errors, disableSignupBtn } = this.state;

    return (
      <>
        <h1>Register</h1>
        <br />
        <br />
        <Container>
          <Row className="justify-content-lg-center">
            <Col lg="6">
              <Form validated={validated} onSubmit={e => this.handleSubmit(e)}>
                <Form.Text style={{ color: "red" }}>{errors.server}</Form.Text>

                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Enter username"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.username}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.password}
                    {errors.passwordincorrect}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Confirm password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password2"
                    placeholder="Confirm password"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.password2}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>{errors.name}</Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Surname</Form.Label>
                  <Form.Control
                    type="text"
                    name="surname"
                    placeholder="Enter your surname"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.surname}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>{errors.email}</Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Mobile phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobilePhone"
                    placeholder="Enter your phone number"
                    required
                    onChange={e => this.handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.mobilePhone}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Select
                    name="country"
                    required
                    onChange={e => this.handleChange(e, "country")}
                    options={countryListReactSelect()}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={disableSignupBtn}
                >
                  {disableSignupBtn ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Please wait...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>

              <br />

              {providers.map(provider => (
                <OAuth
                  provider={provider}
                  key={provider}
                  socket={socket}
                  loginUser={registerUser}
                  pageAction="register"
                />
              ))}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register));

import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const intro = () => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>Main Title</h1>

            <br />
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col sm={9}>sm=9</Col>
          <Col sm={3}>sm=3</Col>
        </Row>
      </Container>
    </>
  );
};

export default intro;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner
} from "react-bootstrap";
import {
  getUser,
  editUser
} from "../../../redux/actions/authActions";
import {
  countryListReactSelect,
  getBirthDaysReactSelect,
  getBirthMonthsReactSelect,
  getBirthYearsReactSelect
} from "../../../utils/utils";
import { countryList } from "../../../config/config";
import Select from "react-select";

const profile = props => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState({
    selectedUser: {},
    employmentIncome: {},
    tradingExperience: {},
    isSocialLogin: false,
    disableEditUserBtn: false,
    disableEIBtn: false,
    disableTEBtn: false,
    errors: {}
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getUser(props.auth.user.id)
      .then(res => {
        setState(prevState => {
          return {
            ...prevState,
            isSocialLogin:
              res.data.user.socialId !== undefined &&
              res.data.user.socialId.length > 0,
            selectedUser: res.data.user,
            employmentIncome: res.data.employmentIncome,
            tradingExperience: res.data.tradingExperience
          };
        });
      })
      .catch(err => console.log(err));
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      window.location.reload();
    }

    if (Object.keys(props.errors).length > 0) {
      setState(prevState => {
        return { ...prevState, errors: props.errors };
      });
    }

    setState(prevState => {
      return {
        ...prevState,
        disableEditUserBtn: false,
        disableEIBtn: false,
        disableTEBtn: false
      };
    });
  }, [props]);

  const handleChangeProfile = (e, target) => {
    let name;
    let value;

    if (
      target === "country" ||
      target === "nationality" ||
      target === "birthDay" ||
      target === "birthMonth" ||
      target === "birthYear"
    ) {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (target === "employmentIncome") {
      const employmentIncome = state.employmentIncome
        ? state.employmentIncome
        : {};
      employmentIncome[name] = value;
      setState(prevState => {
        return { ...prevState, employmentIncome };
      });
    } else if (target === "tradingExperience") {
      const tradingExperience = state.tradingExperience
        ? state.tradingExperience
        : {};
      tradingExperience[name] = value;
      setState(prevState => {
        return { ...prevState, tradingExperience };
      });
    } else {
      const selectedUser = state.selectedUser ? state.selectedUser : {};
      selectedUser[name] = value;
      setState(prevState => {
        return { ...prevState, selectedUser };
      });
    }
  };

  const handleSubmitProfile = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setState(prevState => {
      return { ...prevState, disableEditUserBtn: true };
    });

    const { selectedUser } = state;

    if (
      selectedUser.birthDay === undefined ||
      selectedUser.birthMonth === undefined ||
      selectedUser.birthYear === undefined
    ) {
      setState(prevState => {
        return { ...prevState, disableEditUserBtn: false };
      });

      alert("Please, set your birth date.");

      return false;
    }

    if (form.checkValidity()) {
      props.editUser(selectedUser);
    }
  };

  return (
    <>
      <h1>Profile</h1>
      <br />
      <br />
      <Container>
        <Row className="justify-content-lg-center">
          <Col><Form onSubmit={e => handleSubmitProfile(e)}>
            <Form.Text style={{ color: "red" }}>
              {props.errors.server}
            </Form.Text>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  disabled
                  defaultValue={state.selectedUser.email}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.email}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Enter username"
                  disabled
                  defaultValue={state.selectedUser.username}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.username}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  onChange={e => handleChangeProfile(e, "")}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.password}
                  {props.errors.passwordincorrect}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Confirm password</Form.Label>
                <Form.Control
                  type="password"
                  name="password2"
                  placeholder="Confirm password"
                  onChange={e => handleChangeProfile(e, "")}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.password2}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="2" xs="6">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  as="select"
                  name="title"
                  required
                  onChange={e => handleChangeProfile(e, "")}
                  value={state.selectedUser.title}
                >
                  <option value="">Select title</option>
                  <option>Mr.</option>
                  <option>Mrs.</option>
                  <option>Dr.</option>
                </Form.Control>
                <Form.Text style={{ color: "red" }}>
                  {props.errors.title}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} md="4" xs="6">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  required
                  onChange={e => handleChangeProfile(e, "")}
                  defaultValue={state.selectedUser.name}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.name}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  type="text"
                  name="surname"
                  placeholder="Enter your surname"
                  required
                  onChange={e => handleChangeProfile(e, "")}
                  defaultValue={state.selectedUser.surname}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.surname}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Col md="6" xs="12">
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Birth day</Form.Label>
                    <Select
                      name="birthDay"
                      required
                      onChange={e =>
                        handleChangeProfile(e, "birthDay")
                      }
                      options={getBirthDaysReactSelect()}
                      value={{
                        label: state.selectedUser.birthDay || "",
                        value: state.selectedUser.birthDay || ""
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Birth month</Form.Label>
                    <Select
                      name="birthMonth"
                      required
                      onChange={e =>
                        handleChangeProfile(e, "birthMonth")
                      }
                      options={getBirthMonthsReactSelect()}
                      value={{
                        label: state.selectedUser.birthMonth || "",
                        value: state.selectedUser.birthMonth || ""
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Birth year</Form.Label>
                    <Select
                      name="birthYear"
                      required
                      onChange={e =>
                        handleChangeProfile(e, "birthYear")
                      }
                      options={getBirthYearsReactSelect()}
                      value={{
                        label: state.selectedUser.birthYear || "",
                        value: state.selectedUser.birthYear || ""
                      }}
                    />
                  </Form.Group>
                </Form.Row>
              </Col>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Mobile phone</Form.Label>
                <Form.Control
                  type="text"
                  name="mobilePhone"
                  placeholder="Enter your phone number"
                  required
                  onChange={e => handleChangeProfile(e, "")}
                  defaultValue={state.selectedUser.mobilePhone}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.mobilePhone}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Street number</Form.Label>
                <Form.Control
                  type="text"
                  name="addressNo"
                  placeholder="Enter your street number"
                  required
                  onChange={e => handleChangeProfile(e, "")}
                  defaultValue={state.selectedUser.addressNo}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.address}
                </Form.Text>
              </Form.Group>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Street address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="Enter your street address"
                  required
                  onChange={e => handleChangeProfile(e, "")}
                  defaultValue={state.selectedUser.address}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.address}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="Enter your city"
                  required
                  onChange={e => handleChangeProfile(e, "")}
                  defaultValue={state.selectedUser.city}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.city}
                </Form.Text>
              </Form.Group>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  type="text"
                  name="postcode"
                  placeholder="Enter your postcode"
                  required
                  onChange={e => handleChangeProfile(e, "")}
                  defaultValue={state.selectedUser.postcode}
                />
                <Form.Text style={{ color: "red" }}>
                  {props.errors.postcode}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>County</Form.Label>
                <Form.Control
                  type="text"
                  name="county"
                  placeholder="Enter your county"
                  required
                  onChange={e => handleChangeProfile(e, "county")}
                  defaultValue={state.selectedUser.county}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Country</Form.Label>
                <Select
                  name="country"
                  required
                  onChange={e => handleChangeProfile(e, "country")}
                  options={countryListReactSelect()}
                  value={{
                    label: countryList[state.selectedUser.country],
                    value: state.selectedUser.country
                  }}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Nationality</Form.Label>
                <Select
                  name="country"
                  required
                  onChange={e =>
                    handleChangeProfile(e, "nationality")
                  }
                  options={countryListReactSelect()}
                  value={{
                    label:
                      countryList[state.selectedUser.nationality],
                    value: state.selectedUser.nationality
                  }}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row className="justify-content-between">
              <Button
                variant="secondary"
                onClick={() => window.location.reload()}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={state.disableEditUserBtn}
              >
                {state.disableEditUserBtn ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Please wait...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form.Row>
          </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

profile.propTypes = {
  auth: PropTypes.object.isRequired,
  editUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { editUser }
)(profile);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../../redux/actions/authActions";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";

// SOCIAL LOGIN DEPENDENCIES
import io from "socket.io-client";
import OAuth from "../SocialLogin/OAuth";
import { API_URL } from "../../../config/config";
const socket = io(API_URL);
const providers = ["twitter", "google", "facebook"];
// SOCIAL LOGIN DEPENDENCIES END

class Login extends Component {
  state = {
    username: "",
    password: "",
    socialId: "",
    errors: {},
    disableLoginBtn: false,
    validated: false
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.role === "ADMIN") {
        this.props.history.push("/admin");
      } else {
        this.props.history.push("/dashboard");
      }
    }
  }

  static getDerivedStateFromProps(newProps) {
    if (newProps.auth.isAuthenticated) {
      if (newProps.auth.user.role === "ADMIN") {
        newProps.history.push("/admin");
      } else {
        newProps.history.push("/dashboard");
      }
    }

    if (Object.keys(newProps.errors).length > 0) {
      return {
        errors: newProps.errors,
        disableLoginBtn: false
      };
    }

    // No state update necessary
    return null;
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity()) {
      this.setState({ disableLoginBtn: true });

      const userData = {
        username: this.state.username,
        password: this.state.password,
        socialId: this.state.socialId
      };

      this.props.loginUser(userData);
    }
  };

  render() {
    const { validated, errors, disableLoginBtn } = this.state;

    return (
      <>
        <h1>Login</h1>
        <br />
        <br />
        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Form validated={validated} onSubmit={e => this.handleSubmit(e)}>
                <Form.Text style={{ color: "red" }}>
                  {errors.server}
                  {errors.usernameorpassword}
                </Form.Text>

                <Form.Group controlId="validationUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Enter username"
                    required
                    onChange={e => this.handleChange(e)}
                  />
                </Form.Group>

                <Form.Group controlId="validationPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    required
                    onChange={e => this.handleChange(e)}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={disableLoginBtn}
                >
                  {disableLoginBtn ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Please wait...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>

              <br />

              {providers.map(provider => (
                <OAuth
                  provider={provider}
                  key={provider}
                  socket={socket}
                  loginUser={loginUser}
                  pageAction="login"
                />
              ))}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);

import React from "react";

const textPage = () => (
  <div className="container">
    <div className="row">
      <div className="col s12">
        <h1>Privacy / Terms</h1>
        <p className="left-align">
          Interdum et malesuada fames ac ante ipsum primis in faucibus. Quisque
          porta, est in lobortis consectetur, lectus sem gravida dolor, quis
          aliquet dolor justo a augue. Nam at lobortis nisi. Sed id metus a
          turpis finibus tincidunt dignissim at nulla. Duis magna tellus,
          egestas vel lorem ut, faucibus ultricies orci. Vivamus semper nulla eu
          placerat varius. Nunc suscipit eget arcu nec consequat. Integer
          tincidunt ligula rhoncus turpis aliquam ullamcorper. Integer a semper
          magna. Nunc rutrum tincidunt cursus. Phasellus quis congue purus.
          Aenean ornare, quam non ullamcorper efficitur, urna magna egestas
          nisl, nec bibendum sapien sem eu velit. Etiam pharetra aliquet luctus.
          Aliquam ultricies ex eu ullamcorper viverra. Quisque lacus nulla,
          euismod at lacus egestas, suscipit pharetra nibh. Integer tincidunt
          fringilla est eget porttitor.
        </p>
      </div>
    </div>
  </div>
);

export default textPage;

import React from "react";

const textPage = () => (
  <div className="container">
    <div className="row">
      <div className="col s12">
        <h1>How It Works</h1>
        <p className="left-align">
          Aliquam erat volutpat. Suspendisse potenti. Nulla vitae neque ac ipsum
          venenatis varius a eget ligula. Vestibulum tincidunt gravida nunc at
          dapibus. Mauris ut elit sed justo varius finibus. Pellentesque aliquam
          lectus arcu, id faucibus nisi pretium sit amet. Vivamus sed lorem
          sodales, elementum odio ut, maximus dolor. Aliquam vel ornare tortor.
          Quisque pretium et neque ut congue. Vestibulum eu tortor ex. Morbi
          sollicitudin blandit urna, quis dignissim leo ultricies a. Vestibulum
          viverra pellentesque elit, sit amet laoreet orci maximus ac. In
          aliquet vel justo vitae feugiat. Nam et auctor ipsum. Etiam ultricies
          eu mauris a euismod. Ut sed neque vehicula lacus aliquam pulvinar
          vitae in ligula.
        </p>
      </div>
    </div>
  </div>
);

export default textPage;

import axios from "axios";
import { GET_ERRORS, GET_SUCCESS } from "./types";
import { API_URL } from "../../config/config";

// Get all projects
export const adminGetProjects = (
  status,
  fraudType,
  dateFrom,
  dateTo,
  limit
) => {
  return axios.post(API_URL + "/api/admin/projects/list/", {
    status,
    fraudType,
    dateFrom,
    dateTo,
    limit
  });
};

// Edit project
export const adminEditProject = userData => dispatch => {
  axios
    .post(API_URL + "/api/admin/projects/editProject", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." }
      })
    );
};

// Get all users
export const getUsers = (status, limit) => {
  return axios.get(API_URL + "/api/admin/users/list/" + status + "/" + limit);
};

// Add user
export const adminAddUser = userData => dispatch => {
  axios
    .post(API_URL + "/api/admin/users/register", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." }
      })
    );
};

// Edit user
export const adminEditUser = userData => dispatch => {
  axios
    .post(API_URL + "/api/admin/users/editUser", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." }
      })
    );
};

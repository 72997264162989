import React from "react";
import { userStatuses, countryList } from "../config/config";

export const cutLongText = (text, limit) => {
  const lmt = parseInt(limit);
  return text.length > lmt ? text.substr(0, lmt) + "..." : text;
};

export const getUserStatusSelectListOptions = () =>
  Object.keys(userStatuses).map(userKey => (
    <option key={userKey} value={userKey}>
      {userStatuses[userKey]}
    </option>
  ));

export const getCountrySelectListOptions = () =>
  Object.keys(countryList).map(countryKey => (
    <option key={countryKey} value={countryKey}>
      {countryList[countryKey]}
    </option>
  ));

export const countryListReactSelect = () => {
  let countries = [];
  Object.keys(countryList).map((value, index) =>
    countries.push({ value: value, label: countryList[value] })
  );
  return countries;
};

export const getBirthDaysReactSelect = () => {
  let days = [];
  for (let i = 1; i <= 31; i++) {
    days.push({ value: i, label: i });
  }
  return days;
};

export const getBirthMonthsReactSelect = () => {
  let months = [];
  for (let i = 1; i <= 12; i++) {
    months.push({ value: i, label: i });
  }
  return months;
};

export const getBirthYearsReactSelect = () => {
  let d = new Date();
  let y = d.getFullYear();

  let years = [];
  for (let i = y - 100; i <= y; i++) {
    years.push({ value: i, label: i });
  }
  return years;
};

export const formatDateForDateFilter = date => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

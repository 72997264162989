import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../config/config";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

class ConfirmEmail extends Component {
  state = {
    confirming: true,
    emailMessage: ""
  };

  componentDidMount = () => {
    const { id } = this.props.match.params;

    fetch(`${API_URL}/email/confirm/${id}`)
      .then(res => res.json())
      .then(data => {
        this.setState({ confirming: false, emailMessage: data.msg });
      })
      .catch(err => console.log(err));
  };

  // While the email address is being confirmed on the server a spinner is
  // shown that gives visual feedback. Once the email has been confirmed the
  // spinner is stopped and turned into a button that takes the user back to the
  // <Landing > component so they can confirm another email address.
  render = () => (
    <>
      {this.state.confirming ? (
        <Spinner animation="border" />
      ) : (
        <Container>
          <Row className="justify-content-lg-center">
            <Col lg="6">
              <br />
              <br />
              <p>{this.state.emailMessage}</p>
              <br />
              <br />
              <Link to="/login">
                <Button variant="primary" type="button">
                  Log In
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default ConfirmEmail;

import React, { Component } from "react";
import classes from "./Layout.module.css";
import Navbar from "../../components/Navigation/Navbar";
import Footer from "../../components/Footer/Footer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";

class Layout extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const HeaderWithRouter = withRouter(Navbar);

    return (
      <>
        <HeaderWithRouter logoutClicked={this.onLogoutClick} />
        <main className={classes.Content}>{this.props.children}</main>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Layout);

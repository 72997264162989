import React, { Component } from "react";
import { API_URL } from "../../../config/config";
import { Button } from "react-bootstrap";
import { loginUser, registerUser } from "../../../redux/actions/authActions";
import { connect } from "react-redux";

class OAuth extends Component {
  state = {
    user: {},
    disabled: "",
    errors: {}
  };

  check;

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const { socket, provider, pageAction } = this.props;

    socket.on(provider, user => {
      if (this.popup !== undefined) {
        this.popup.close();
      }

      if (this._isMounted) {
        this.setState({ user });

        if (pageAction === "login") {
          this.props.loginUser(user);
        } else {
          this.props.registerUser(user, this.props.history);
        }
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.check);
  }

  // Routinely checks the popup to re-enable the login button
  // if the user closes the popup without authenticating.
  checkPopup() {
    this.check = setInterval(() => {
      const { popup } = this;
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(this.check);
        this.setState({ disabled: "" });
      }
    }, 1000);
  }

  // Launches the popup by making a request to the server and then
  // passes along the socket id so it can be used to send back user
  // data to the appropriate socket on the connected client.
  openPopup() {
    const { provider, socket } = this.props;
    const width = 600,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const url = `${API_URL}/${provider}?socketId=${socket.id}`;

    return window.open(
      url,
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
    );
  }

  // Kicks off the processes of opening the popup on the server and listening
  // to the popup. It also disables the login button so the user can not
  // attempt to login to the provider twice.
  startAuth(e) {
    if (!this.state.disabled) {
      e.preventDefault();
      this.popup = this.openPopup();
      this.checkPopup();
      this.setState({ disabled: "disabled" });
    }
  }

  // closeCard() {
  //   this.setState({ user: {} });
  // }

  render() {
    const { provider } = this.props;
    const { disabled } = this.state;

    return (
      <Button
        variant="info"
        disabled={disabled}
        className="mx-1"
        onClick={this.startAuth.bind(this)}
      >
        {provider}
      </Button>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, registerUser }
)(OAuth);

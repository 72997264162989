import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userRoles, countryList } from "../../../../config/config";
import {
  getUserStatusSelectListOptions,
  countryListReactSelect,
  getBirthDaysReactSelect,
  getBirthMonthsReactSelect,
  getBirthYearsReactSelect
} from "../../../../utils/utils";
import {
  getUsers,
  adminEditUser
} from "../../../../redux/actions/adminActions";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Form,
  Spinner
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Link } from "react-router-dom";
import Select from "react-select";

class UserList extends Component {
  state = {
    users: {},
    showUserStatus: "enabled",
    selectedUser: {},
    loadingUsers: true,
    disableEditUserBtn: false,
    editingUser: false,
    validated: false,
    errors: {}
  };

  componentDidMount() {
    getUsers(this.state.showUserStatus, 0)
      .then(res => {
        this.setState({ users: res.data.users, loadingUsers: false });
      })
      .catch(err => console.log(err));
  }

  static getDerivedStateFromProps(newProps) {
    if (Object.keys(newProps.success).length > 0) {
      window.location.reload();
    }

    if (Object.keys(newProps.errors).length > 0) {
      return {
        errors: newProps.errors,
        disableEditUserBtn: false
      };
    }

    // No state update necessary
    return null;
  }

  handleCloseModal = () => {
    this.setState({ editingUser: false });
  };

  handleUserStatusChange = e => {
    const userStatus = e.target.value;
    this.setState({ showUserStatus: userStatus, loadingUsers: true });

    getUsers(userStatus, 0)
      .then(res => {
        this.setState({ users: res.data.users, loadingUsers: false });
      })
      .catch(err => console.log(err));
  };

  handleChangeProfile = (e, target) => {
    let name;
    let value;

    if (
      target === "country" ||
      target === "nationality" ||
      target === "birthDay" ||
      target === "birthMonth" ||
      target === "birthYear"
    ) {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "emailVerified") {
      value = e.target.checked;
    }

    const { selectedUser } = this.state;
    selectedUser[name] = value;
    this.setState({ selectedUser });
  };

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const { selectedUser } = this.state;

    if (
      (userRoles[this.props.auth.user.role] < userRoles["SYSOP"] &&
        selectedUser.role === "SYSOP") ||
      userRoles[selectedUser.role] === userRoles["ADMIN"]
    ) {
      return false;
    }

    this.setState({ disableEditUserBtn: true });

    if (form.checkValidity()) {
      const currentUser = selectedUser;
      currentUser.status =
        userRoles[selectedUser.role] >= userRoles["ADMIN"]
          ? "enabled"
          : selectedUser.status;

      if (this.props.auth.user.role === "SYSOP") {
        currentUser.role = selectedUser.role;
      }

      delete currentUser.documents;

      this.props.adminEditUser(currentUser);
    }
  };

  render() {
    const {
      validated,
      errors,
      users,
      loadingUsers,
      selectedUser,
      disableEditUserBtn,
      showUserStatus
    } = this.state;

    const columns = [
      {
        dataField: "name",
        text: "Name",
        sort: true
      },
      {
        dataField: "surname",
        text: "Surname",
        sort: true
      },
      {
        dataField: "username",
        text: "Username",
        sort: true
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
        classes: "breakWord"
      },
      {
        dataField: "mobilePhone",
        text: "Mobile phone",
        sort: true
      },
      {
        dataField: "country",
        text: "Country",
        sort: true
      }
    ];

    const defaultSorted = [
      {
        dataField: "name",
        order: "asc"
      }
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({ editingUser: true, selectedUser: row });
      }
    };

    let data = [];
    if (!!users) {
      for (let i = 0; i < users.length; i++) {
        data.push(users[i]);
      }
    }

    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              <br/>
              <Button
                type="button"
                variant="primary"
                as={Link}
                className="float-right"
                to="/admin/users/add-user"
              >
                Add user
              </Button>
              User Status:
              <Button
                type="button"
                variant="primary"
                value="pending"
                className="mx-1"
                onClick={e => this.handleUserStatusChange(e)}
              >
                Pending
              </Button>
              <Button
                type="button"
                variant="primary"
                value="enabled"
                onClick={e => this.handleUserStatusChange(e)}
              >
                Enabled
              </Button>
              <Button
                type="button"
                variant="primary"
                value="disabled"
                className="mx-1"
                onClick={e => this.handleUserStatusChange(e)}
              >
                Disabled
              </Button>
              <Button
                type="button"
                variant="primary"
                value="blocked"
                onClick={e => this.handleUserStatusChange(e)}
              >
                Blocked
              </Button>
              <Button
                type="button"
                variant="primary"
                value="all"
                className="mx-1"
                onClick={e => this.handleUserStatusChange(e)}
              >
                Show all
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="pt-3">
                Users {loadingUsers ? <Spinner animation="border" /> : null}
              </h1>

              <br />
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col>
              <div>User Status: {showUserStatus}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <BootstrapTable
                bootstrap4
                keyField="_id"
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
                hover={true}
                rowEvents={rowEvents}
              />

              <Modal
                show={this.state.editingUser}
                onHide={this.handleCloseModal}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit user</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Form
                    validated={validated}
                    onSubmit={e => this.handleSubmit(e)}
                  >
                    {userRoles[this.props.auth.user.role] >
                    userRoles["ADMIN"] && selectedUser.role !== "SYSOP" ? (
                      <Form.Group>
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          as="select"
                          name="role"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.role}
                        >
                          <option value="">Choose user role</option>
                          <option value="ADMIN">Admin</option>
                          <option value="USER">User</option>
                        </Form.Control>
                      </Form.Group>
                    ) : null}

                    {userRoles[this.props.auth.user.role] >
                    userRoles["ADMIN"] ||
                    (userRoles[this.props.auth.user.role] ===
                      userRoles["ADMIN"] &&
                      selectedUser.role !== "ADMIN") ? (
                      <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          name="status"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.status}
                        >
                          <option value="">Choose user status</option>
                          {getUserStatusSelectListOptions()}
                        </Form.Control>
                      </Form.Group>
                    ) : null}

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          disabled
                          defaultValue={selectedUser.email}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.email}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          type="text"
                          name="username"
                          placeholder="Enter username"
                          disabled
                          defaultValue={selectedUser.username}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.username}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter password"
                          onChange={e => this.handleChangeProfile(e, "")}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.password}
                          {errors.passwordincorrect}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password2"
                          placeholder="Confirm password"
                          onChange={e => this.handleChangeProfile(e, "")}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.password2}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="2" xs="6">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          as="select"
                          name="title"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.title}
                        >
                          <option value="">Select title</option>
                          <option>Mr.</option>
                          <option>Mrs.</option>
                          <option>Dr.</option>
                        </Form.Control>
                        <Form.Text style={{ color: "red" }}>
                          {errors.title}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group as={Col} md="4" xs="6">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.name}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.name}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Surname</Form.Label>
                        <Form.Control
                          type="text"
                          name="surname"
                          placeholder="Enter your surname"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.surname}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.surname}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Col md="6" xs="12">
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Birth day</Form.Label>
                            <Select
                              name="birthDay"
                              required
                              onChange={e =>
                                this.handleChangeProfile(e, "birthDay")
                              }
                              options={getBirthDaysReactSelect()}
                              value={{
                                label: selectedUser.birthDay || "",
                                value: selectedUser.birthDay || ""
                              }}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Birth month</Form.Label>
                            <Select
                              name="birthMonth"
                              required
                              onChange={e =>
                                this.handleChangeProfile(e, "birthMonth")
                              }
                              options={getBirthMonthsReactSelect()}
                              value={{
                                label: selectedUser.birthMonth || "",
                                value: selectedUser.birthMonth || ""
                              }}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Birth year</Form.Label>
                            <Select
                              name="birthYear"
                              required
                              onChange={e =>
                                this.handleChangeProfile(e, "birthYear")
                              }
                              options={getBirthYearsReactSelect()}
                              value={{
                                label: selectedUser.birthYear || "",
                                value: selectedUser.birthYear || ""
                              }}
                            />
                          </Form.Group>
                        </Form.Row>
                      </Col>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Mobile phone</Form.Label>
                        <Form.Control
                          type="text"
                          name="mobilePhone"
                          placeholder="Enter your phone number"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.mobilePhone}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.mobilePhone}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Street number</Form.Label>
                        <Form.Control
                          type="text"
                          name="addressNo"
                          placeholder="Enter your street number"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.addressNo}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.address}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Street address</Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          placeholder="Enter your street address"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.address}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.address}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          placeholder="Enter your city"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.city}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.city}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control
                          type="text"
                          name="postcode"
                          placeholder="Enter your postcode"
                          required
                          onChange={e => this.handleChangeProfile(e, "")}
                          defaultValue={selectedUser.postcode}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.postcode}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>County</Form.Label>
                        <Form.Control
                          type="text"
                          name="county"
                          placeholder="Enter your county"
                          required
                          onChange={e => this.handleChangeProfile(e, "county")}
                          defaultValue={selectedUser.county}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Country</Form.Label>
                        <Select
                          name="country"
                          required
                          onChange={e => this.handleChangeProfile(e, "country")}
                          options={countryListReactSelect()}
                          value={{
                            label: countryList[selectedUser.country],
                            value: selectedUser.country
                          }}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Nationality</Form.Label>
                        <Select
                          name="nationality"
                          required
                          onChange={e =>
                            this.handleChangeProfile(e, "nationality")
                          }
                          options={countryListReactSelect()}
                          value={{
                            label: countryList[selectedUser.nationality],
                            value: selectedUser.nationality
                          }}
                        />
                        <Form.Text style={{ color: "red" }}>
                          {errors.nationality}
                        </Form.Text>
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="emailVerified">
                      <Form.Check
                        type="checkbox"
                        name="emailVerified"
                        label="Email verified"
                        checked={selectedUser.emailVerified}
                        onChange={e => this.handleChangeProfile(e, "")}
                      />
                    </Form.Group>

                    <Form.Text style={{ color: "red" }}>
                      {errors.errors || errors.server}
                      <br />
                      <br />
                    </Form.Text>

                    <Form.Row className="justify-content-between">
                      <Button
                        variant="secondary"
                        onClick={this.handleCloseModal}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="primary"
                        type="submit"
                        disabled={disableEditUserBtn}
                      >
                        {disableEditUserBtn ? (
                          <>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Please wait...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form.Row>
                  </Form>
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

UserList.propTypes = {
  adminEditUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { adminEditUser }
)(UserList);

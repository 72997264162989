import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

class upgradePlan extends Component {
  render() {
    return (
      <>
        <h1>Upgrade Plan</h1>
        <br />
        <br />
        <Container>
          <Row className="justify-content-lg-center">
            <Col>test</Col>
          </Row>
        </Container>
      </>
    );
  }
}

upgradePlan.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(upgradePlan);

import React from "react";
import classes from "./Footer.module.css";

const footer = () => {
  return (
    <footer className={classes.footer}>
      <div className="bg-dark text-white d-flex justify-content-between">
        <div />
        <div>BTC Wallet Watch</div>
        <div className="pr-2">Ver: 1.0.0</div>
      </div>
    </footer>
  );
};

export default footer;
